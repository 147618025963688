import { defineStore } from "pinia";
import axios from 'axios';
import { useRuntimeConfig } from "nuxt/app";

export const useNotificationStore = defineStore("notification", {
  state: () => ({
  }),
  getters: {
    accountApiUrl: () => {
      const config = useRuntimeConfig();
      return config.public.vcrmAccountApiUrl as string;
    }
  },
  actions: {
    getVcrmAccountToken() {
      if (!document) {
        return false;
      }
      const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
      if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
        return vue_cookie.split("=")[1];
      }
    },
    async fetchNotifications(page: string) {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.get(`${this.accountApiUrl}/notifications?page=${page || 1}`, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web"
        }
      })
        .then((res) => {
          return res.data
        })
    },
    async markRead(notificationId: string) {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.put(`${this.accountApiUrl}/notifications/${notificationId}/mark-read`, {}, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web"
        }
      });
    },
    async markAllRead(notificationId: string) {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.put(`${this.accountApiUrl}/notifications/mark-read-all`, {}, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web"
        }
      });
    },
    async fetchUnreadNotifications() {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.get(`${this.accountApiUrl}/notifications/get-unread`, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web"
        }
      }).then((res) => res.data.total);
    },
  }
});